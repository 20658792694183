import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'

import SmallImage from '../components/Image/SmallImage'
import ImgLightBox from '../components/lightbox/ImgLightBox'
const GaleriPage = () => {
  const lightBoxRef = useRef()
  const { getImagesByFolder } = useStore()
  const imgArray = getImagesByFolder('hasir-tel')
  const lightBoxImages = imgArray.map((imgItem) => imgItem.original.src)
  return (
    <>
      <SEO
        title='Galeri - Erez Kaynak'
        description='Emiş filtresi Silindir Saç Filtre İmalatı Seperatör Filtresi Silindir Paslanmaz Filtre İmalatı Seperatör Elek Filtresi Pislik Tutucu Paslanmaz Filtre Pislik Tutucu Paslanmaz Filtre Pislik Tutucu Paslanmaz Filtre Pislik Tutucu Paslanmaz Filtre Paslanmaz Silindir Filtre Pislik Tutucu Paslanmaz Filtre Silindir Paslanmaz'
        keywords='fens,Fens Teli İmalatı,fens teli,paslanmaz fens,paslanmaz fens teli,galvaniz fens,galvaniz fens teli,tel fens,fens tel,fen teli fiyatları,fens fiyatları,fens teli istanbul,boyalı fens teli,yeşil fens teli'
        url='galeri'
      />
      <ImgLightBox ref={lightBoxRef} gallery={lightBoxImages} />
      <Box paddingX={3} paddingY={5} style={{ backgroundColor: 'rgba(0,0,0,.2' }}>
        <GeneralCarousel />
        <Grid container spacing={3} justify='center'>
          {imgArray.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={`galeri-item-${index}`}>
              <ImgWrapper onClick={() => lightBoxRef.current.openLightBox(index)}>
                <SmallImage img={item} />
              </ImgWrapper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}
export default GaleriPage
