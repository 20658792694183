import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    height: 'auto',
    border: `6px solid rgba(0,0,0,.2)`,
    boxShadow: `0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12)`,
  },
  imgWrapper: {
    textAlign: 'center',
  },
}))
const SmallImage = ({ img, alt, style }) => {
  const classes = useStyles()

  return (
    <div className={classes.imgWrapper}>
      <img
        className={classes.root}
        loading='lazy'
        src={img.sm.src}
        alt={alt || 'Erez Kaynak çelik hasır ve filtre elek imalatı'}
        style={style}
        title={alt || 'Erez Kaynak çelik hasır ve filtre elek imalatı'}
      />
    </div>
  )
}
export default memo(SmallImage)
